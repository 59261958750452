<template>
  <list-crud
    :loading="loading"
    :per-page.sync="perPage"
    :per-page-options="perPageOptions"
    :search-query.sync="searchQuery"
    :items="items"
    :table-columns="tableColumns"
    :sort-by.sync="sortBy"
    :is-sort-dir-desc.sync="isSortDirDesc"
    :current-page.sync="currentPage"
    :from="from"
    :to="to"
    :total-items="totalItems"
    :hide-create-btn="!$store.getters['workingMode/checkPermission']('manage_warehouses')"
    @click-btn-create="$router.push({ name: 'portfolios.create', params: { siteId: siteId } })"
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'portfolios.show', params: { id: data.item.id, siteId: siteId } }">
        {{ data.item.name }}
      </b-link>
    </template>

    <template #cell(description)="data">
      <div class="small text-wrap-pl clamp-2">
        {{ data.item.description }}
      </div>
    </template>

    <template #cell(is_public)="data">
      <b-badge
        pill
        variant=""
        class="text-capitalize"
        :variant="data.item.is_public ? 'light-success' : 'light-secondary'"
      >
        {{ data.item.is_public ? 'Вкл' : 'Выкл' }}
      </b-badge>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item :to="{ name: 'portfolios.show', params: { id: data.item.id, siteId: siteId } }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Посмотреть</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$store.getters['workingMode/checkPermission']('manage_warehouses')"
          :to="{ name: 'portfolios.edit', params: { id: data.item.id, siteId: siteId } }"
        >
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Изменить</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$store.getters['workingMode/checkPermission']('manage_warehouses')"
          @click="deleteItem(data.item)"
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Удалить</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </list-crud>
</template>

<script>
import {
  getCurrentInstance, onMounted,
} from 'vue'
import get from 'lodash/get'
import {
  BLink, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import useCrudList from '@/composables/useCrudList'
import { getPortfolios, deletePortfolio } from '@/services/main-api/sites/portfolio'
import ListCrud from '@/components/crud/ListCrud.vue'

export default {
  name: 'ListView',
  components: {
    ListCrud,
    BLink,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  props: {
    siteId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    const getItems = async () => {
      let search = null
      if (instance.searchQuery) {
        search = `${instance.searchQuery}`
      }

      try {
        const res = await getPortfolios({
          site: props.siteId,
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
          includes: 'real_address',
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'id',
      isSortDirDesc: true,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'description', label: 'Описание', sortable: false },
        { key: 'is_public', label: 'Видимость', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
      getItems,
      getMoreData: async () => {
      },
      deleteEntity: item => deletePortfolio(item.id),
      getQuestionTextBeforeDeletion: item => `Портфолио <strong>${item.name}</strong>> будет удалено`,
      getTextAfterDeletion: () => 'Портфолио было удалено.',
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,

      get,
    }
  },
}
</script>
