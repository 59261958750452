<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <slot name="header" />
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показать</label>
            <v-select
              :value="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="$emit('update:perPage', $event)"
            />
            <label>элементов</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="!hideSearch"
                :value="searchQuery"
                class="d-inline-block"
                :class="{
                  'mr-1': !hideCreateBtn,
                }"
                placeholder="Поиск..."
                @input="$emit('update:searchQuery', `${$event}`.trim())"
              />
              <b-button
                v-if="!hideCreateBtn"
                variant="primary"
                @click="$emit('click-btn-create')"
              >
                <span class="text-nowrap">{{ btnCreateText }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <slot name="header-bottom" />
      </div>

      <b-table
        ref="refCompanyListTable"
        class="position-relative list-view-table"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by="sortBy"
        show-empty
        empty-text="Ничего не найдено!"
        :sort-desc="isSortDirDesc"
        no-local-sorting
        :selectable="selectable"
        :select-mode="selectMode"
        @update:sortBy="$emit('update:sortBy', $event)"
        @update:sortDesc="$emit('update:isSortDirDesc', $event)"
        @row-selected="$emit('row-selected', $event)"
      >
        <template
          v-for="slotName in tableSlots"
          #[slotName]="data"
        >
          <slot
            :name="slotName"
            v-bind="data"
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано с {{ from }} по {{ to }} из {{ totalItems }} элементов</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              :value="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="$emit('update:currentPage', $event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BOverlay, BLink, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ListCrud',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BOverlay,
    BLink,
    BBadge,

  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    perPageOptions: {
      type: Array,
      default: () => ([10, 25, 50, 100]),
    },
    searchQuery: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    tableColumns: {
      type: Array,
      default: () => ([
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Имя', sortable: true },
        { key: 'actions', label: 'Действия' },
      ]),
    },
    sortBy: {
      type: String,
      default: null,
    },
    isSortDirDesc: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    from: {
      type: Number,
      default: 0,
    },
    to: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    btnCreateText: {
      type: String,
      default: 'Создать',
    },
    hideCreateBtn: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: 'multi',
    },
  },
  computed: {
    tableSlots() {
      return Object.keys(this.$scopedSlots)
        .filter(s => s.includes('cell(') || s.includes('row-details') || s.includes('head('))
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
