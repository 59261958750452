import axiosIns from '@/libs/axios'

export function getPortfolio({ id, includes }) {
  return axiosIns.get(`/sites/portfolio/${id}`, {
    params: {
      includes,
    },
  })
}

export function getPortfolios({
  site, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/sites/portfolio', {
    params: {
      site,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createPortfolio({
  site_id,
  is_public,
  name,
  slug,
  description,
}) {
  return axiosIns.post('/sites/portfolio', {
    site_id,
    is_public,
    name,
    slug,
    description,
  })
}

export function updatePortfolio({
  id,
  is_public,
  name,
  slug,
  description,
}) {
  return axiosIns.patch(`/sites/portfolio/${id}`, {
    is_public,
    name,
    slug,
    description,
  })
}

export function deletePortfolio(id) {
  return axiosIns.delete(`/sites/portfolio/${id}`)
}

export default {
  getPortfolios,
  getPortfolio,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
}
