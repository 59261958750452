import {
  reactive, toRefs, onMounted, watch, getCurrentInstance,
} from 'vue'
import { debounce, get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCrudList({
  sortBy,
  isSortDirDesc = false,
  tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: 'Имя', sortable: true },
    { key: 'actions', label: 'Действия' },
  ],

  perPage = 10,
  perPageOptions = [10, 25, 50, 100],
  getItems = () => {},
  getMoreData = () => {},

  deleteEntity,
  getQuestionTextBeforeDeletion = item => `Сущьность с ID = ${item.id} будет удалена`,
  getTextAfterDeletion = item => `Сущьность с ID = ${item.id} была удалена`,
}) {
  const data = reactive({
    loading: false,

    perPage,
    perPageOptions,
    from: 0,
    to: 0,
    currentPage: 1,
    totalItems: 0,

    sortBy,
    searchQuery: '',
    isSortDirDesc,
    tableColumns,

    items: [],
  })

  const instance = getCurrentInstance().proxy

  const getData = async () => {
    await Promise.all([
      getItems(),
      getMoreData(),
    ])
  }

  const debouncedSearch = debounce(async () => {
    data.loading = true
    data.currentPage = 1
    await getData()
    data.loading = false
  }, 500)

  onMounted(async () => {
    data.loading = true
    await getData()
    data.loading = false
  })

  const deleteItem = async item => {
    const resButton = await instance.$swal({
      title: 'Вы уверены?',
      html: getQuestionTextBeforeDeletion(item),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Да, удалить!',
      cancelButtonText: 'Отмена',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (resButton.value) {
      try {
        await deleteEntity(item)
        getData()
        instance.$toast({
          component: ToastificationContent,
          props: {
            title: 'Удалено!',
            icon: 'BellIcon',
            text: getTextAfterDeletion(item),
            variant: 'success',
          },
        })
      } catch (e) {
        instance.$swal({
          icon: 'error',
          title: 'Ошибка!',
          text: get(e, 'response.data.error', 'Что то пошло не так, попробуйте позже или обратитесь в техническую поддержку.'),
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      }
    }
  }

  watch(() => data.perPage, async () => {
    data.loading = true
    await getData()
    data.loading = false
  })
  watch(() => data.isSortDirDesc, async () => {
    data.loading = true
    await getData()
    data.loading = false
  })
  watch(() => data.sortBy, async () => {
    data.loading = true
    await getData()
    data.loading = false
  })
  watch(() => data.currentPage, async () => {
    data.loading = true
    await getData()
    data.loading = false
  })

  watch(() => data.searchQuery, () => {
    debouncedSearch()
  })

  return {
    ...toRefs(data),
    deleteItem,
  }
}
